import { ErrorAuth } from "../../components/Error"; //Importe une fonction qui gère les erreurs d'authentification.
import React, { useCallback } from "react"; // Va permettre d'inclure le mode strict autour de mes composants.
import { HeaderHome } from "../../components/Header"; // Importe le composant Header dans ma page.
import { StyledMain } from "../../utils/style/Home"; // Importe le style de ma page d'accueil (partie static).
import "../../utils/style/HomeCss/styleHome.css"; // Importe le style de ma page d'accueil (partie dynamique).
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

// Affiche la page d'accueil.
function Home() {
    const [posts, setPosts] = useState([]);

    const urlBack = process.env.REACT_APP_URL_API;
    const token = JSON.parse(localStorage.getItem("token"));
    const userId = JSON.parse(localStorage.getItem("userId"));

    const altImage = (post) => {
        const urlImage = post.imageUrl.split("/images/")[1];
        const extensionImage = urlImage.split(".")[2];
        let alt = "";

        // Crée un alt à partir de l'url de l'image.
        if (extensionImage === "png") {
            alt = urlImage.split(".png")[0];
        } else if (extensionImage === "jpg") {
            alt = urlImage.split(".jpg")[0];
        } else {
            alt = urlImage.split(".jpeg")[0];
        }
        return alt;
    };

    // Regarde la taille de l'image et lui définit l'attribut qui lui est adapté.
    const addClassImg = () => {
        const images = document.getElementsByClassName("img-post");
   
        for(let i = 0; i < images.length; i++) {
            images[i].classList.add(lookSizeImg(images[i]));
        }
    };

    const lookSizeImg = (image) => {
        if(image.naturalHeight >= image.naturalWidth) {
            return "cart__img--portrait";
        } else {
            return "cart__img";
        }
    };

    const checkUserLike = (post, state) => {
        if (state === 'dislike' && post.usersDisliked.find((e) => e === userId)) return true;
        if (state === 'like' && post.usersLiked.find((e) => e === userId)) return true;
        return false;
    }

    const likeDislike = (idPost, element) => {
        let like = 0;
        const choice = {
            type: element.target.classList[0],
            check: element.target.checked
        };
        const checkLike = choice.type === 'like'; 
        const checkDislike = choice.type === 'dislike'; 

        if(choice.check && checkLike) like = 1;
        if(choice.check && checkDislike) like = -1;

        fetch(`${urlBack}/api_2/posts/${idPost}/like`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({like: like}),
        }).then((response) => {
            return response.json();
        }).then((res) => {
            getPosts();
            alert(res);
        }).catch((error) => {
            console.error("Error:", error);
        });
    }

    // Récupère les posts sur le backend pour ensuite les afficher sur la page.
    const getPosts = useCallback(() => {
        fetch(`${urlBack}/api_2/posts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => {
            if(res.ok) return res.json();
        }).then((data) => {
            setPosts(data);
        }).catch(function (err) {
            console.error("Une erreur est survenue");
        });
    }, [token])
    
    const deletePost = (idPost) => {
        fetch(`${urlBack}/api_2/posts/${idPost}`, {
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: "",
        }).then((response) => {
            response.json();
        }).then(() => {
            getPosts();
            alert("Post supprimé !");
        }).catch((error) => {
            console.error("Error:", error);
        });
    }

    useEffect(() => {
        getPosts();
        setTimeout(() => {
            addClassImg();
        }, 200);
    }, [getPosts])

    return token ? (
        <React.StrictMode>
            <HeaderHome />
            <StyledMain>
                { posts.length > 0 && posts.reverse().map((post, id) => {
                    return (
                        <div key={`post-${id}`} className="post">
                            <div className="cart__item__img">
                                <img src={post.imageUrl} alt={altImage(post)} className={`img-post`} />
                            </div>
                            <div className="container">
                                <p className="post_message">{post.postMessage}</p>
                                <div className="container_checkbox">
                                    <div className="container_like" style={{display: `${checkUserLike(post, 'dislike') ? "none" : "block"}`}}>
                                        <input
                                            className="like"
                                            id={`like-${id}`}
                                            type="checkbox"
                                            checked={checkUserLike(post, 'like')}
                                            onChange={(element) => likeDislike(post._id, element)}
                                        />
                                        <label className="heart_like" htmlFor={`like-${id}`}></label>
                                    </div>
                                    <div className="container_dislike" style={{display: `${checkUserLike(post, 'like') ? "none" : "block"}`}}>
                                        <input
                                            className="dislike"
                                            id={`dislike-${id}`}
                                            type="checkbox"
                                            checked={checkUserLike(post, 'dislike')}
                                            onChange={(element) => likeDislike(post._id, element)}
                                        />
                                        <label className="heart_dislike" htmlFor={`dislike-${id}`}></label>
                                    </div>
                                </div>
                                { (userId === post.userId || window.name === "admin") &&
                                    <div className="container_buttons">
                                        <NavLink to={`./modify_post/${post._id}`}>Modifier</NavLink>
                                        <button className="deleted" onClick={() => deletePost(post._id)}>Supprimer</button>
                                    </div>
                                }
                            </div>
                            <div id="container_number_likes_dislikes">
                                <p>{`Nombre de ❤️ = ${post.likes}`}</p>
                                <p>{`Nombre de 💔 = ${post.dislikes}`}</p>
                            </div>
                        </div>
                    )
                })}
            </StyledMain>
        </React.StrictMode>
    ) : (
        <ErrorAuth />
    );
}

export default Home;
